<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/menu.jpg"> -->
    <mealMenu />
  </div>
</template>

<script>
import mealMenu from './components/meal-menu.vue'

export default {
  name: 'App',
  components: {
    mealMenu
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
