/*
 * @Description:
 * @Autor: 郝志杰
 * @Date: 2021-10-29 10:17:56
 * @LastEditors: 郝志杰
 * @LastEditTime: 2024-01-02 13:28:04
 */

import { postRequest } from './http';
let SERVICE_NAME = 'metric-main';
// 获取用户信息
export const getUse = (params) => {
    return postRequest(SERVICE_NAME + '/fIngerTip/testRaperLogs/getWeiXinUser', params);
};
export const getWeiXinUserByDining = (params) => {
    return postRequest(SERVICE_NAME + '/fIngerTip/testRaperLogs/getWeiXinUserByDining', params);
};

// 获取今日是否报餐
export const listPage = (params) => {
    return postRequest(SERVICE_NAME + '/xiaoyuan/diningDetails/listPage', params);
};
// 获取最新图片
export const getImg = (params) => {
    return postRequest(SERVICE_NAME + '/xiaoyuan/cookbook/getNewImg', params);
};
// 提交报餐
export const saveAnswer = (params) => {
    return postRequest(SERVICE_NAME + '/xiaoyuan/diningDetails/save', params);
};
// 获取报餐人数

export const peopleNum = (params) => {
    return postRequest(SERVICE_NAME + '/xiaoyuan/diningDetails/listPage', params);
};