/*
 * @Description: 
 * @Autor: 郝志杰
 * @Date: 2023-11-13 11:38:48
 * @LastEditors: 郝志杰
 * @LastEditTime: 2023-11-13 11:57:22
 */
import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'; // 饿了么UI
import 'element-ui/lib/theme-chalk/index.css'; // 饿了么UI样式
Vue.use(ElementUI); // elementUI 注册
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
